import React, { useEffect, useMemo, useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import { css, Global, useTheme } from "@emotion/react";
import EmailValidator from "email-validator";
import { isEmpty, isEqual } from "lodash";

import BannerForAdvertisement from "core/Components/BannerForAdvertisement/BannerForAdvertisement";
import DropdownButton from "core/Components/Buttons/DropdownButton/DropdownButton";
import RoundQuantityButton from "core/Components/Buttons/QuantityButton/RoundQuantityButton";
import ContentCombine from "core/Components/ContentCombine";
import ContentSection from "core/Components/ContentSection";
import SectionContainer from "core/Components/SectionContainer";
import Loading from "core/Components/Utils/Loading";
import Wrapper from "core/Components/Wrapper";
import NoData from "core/Components/Errors/NoData";
import FormModal from "core/Components/FormModal";
import Form from "core/Components/Form/Form";
import FormActions from "core/Components/Form/FormActions";
import { FormDropdownOption } from "core/Components/Form/DropDown";
import useToast from "core/hooks/useToast";
import useForm, { FieldList } from "core/hooks/useForm";
import useSetState from "core/hooks/useSetState";
import useBreakpoint from "core/hooks/useBreakpoint";
import { CREATE_RESOURCE_ORDER } from "core/includes/queries";
import { isOnly, isUp } from "theme/breakpoints";

import useListBreakpoint from "pages/Components/ListPage/hooks/useListBreakpoint";
import PageSection from "pages/Components/ListPage/PageSection";

import {
    PRODUCT_SORT_OPTIONS,
    PRODUCT_PRICE_RANGE_OPTIONS,
    PRODUCT_SUITABILITY_INIT_OPTION,
    PRODUCT_GROUP_INIT_OPTION,
    HEAR_FROM_OPTIONS,
    DROPDOWN_INIT_OPTION,
    PRODUCT_ORDER_INIT_FIELDS,
    ORDER_RESPONSE_MESSAGE,
    DROPDOWN_BACKGROUND_COLOUR,
    SORT_DROPDOWN_OPTION_BACKGROUND_COLOUR,
    SORT_DROPDOWN_OPTION_BACKGROUND_HOVERED_COLOUR
} from "pages/Components/ProductListPage/includes/constants";
import useProductListPageData from "pages/Components/ProductListPage/hooks/useProductListPageData";
import PageHeader from "pages/Components/ProductListPage/components/PageHeader";
import DropdownContainer from "pages/Components/ProductListPage/components/DropdownContainer";
import ProductsSection from "pages/Components/ProductListPage/components/ProductsSection";
import ProductContainer from "pages/Components/ProductListPage/components/ProductContainer";
import ProductImage from "pages/Components/ProductListPage/components/ProductImage";
import ProductText from "pages/Components/ProductListPage/components/ProductText";
import ProductImageContainer from "pages/Components/ProductListPage/components/ProductImageContainer";
import Price from "pages/Components/ProductListPage/components/Price";
import Description from "pages/Components/ProductListPage/components/Description";
import OrderButton from "pages/Components/ProductListPage/components/OrderButton";
import ModalHeader from "pages/Components/ProductListPage/components/ModalHeader";
import ModalText from "pages/Components/ProductListPage/components/ModalText";
import FormText from "pages/Components/ProductListPage/components/FormText";
import FormContainer from "pages/Components/ProductListPage/components/FormContainer";
import FormLabelledField from "pages/Components/ProductListPage/components/FormLabelledField";
import FormButton from "pages/Components/ProductListPage/components/FormButton";
import FormInput from "pages/Components/ProductListPage/components/FormInput";
import FormDropDown from "pages/Components/ProductListPage/components/FormDropDown";
import OrderInfoText from "pages/Components/ProductListPage/components/OrderInfoText";
import OrderSummaryTable from "pages/Components/ProductListPage/components/OrderSummaryTable";
import GuidanceText from "pages/Components/ProductListPage/components/GuidanceText";
import GuidanceAndOrderButtonContainer from "pages/Components/ProductListPage/components/GuidanceAndOrderButtonContainer";
import PageHeaderAndDropdownsContainer from "pages/Components/ProductListPage/components/PageHeaderAndDropdownsContainer";
import BorderForSpaces from "pages/Components/ProductListPage/components/BorderForSpaces";
import DiscountedPriceImage from "pages/Components/ProductListPage/components/DiscountedPriceImage";
import DiscountedPriceAndProductImageContainer from "pages/Components/ProductListPage/components/DiscountedPriceAndProductImageContainer";
import Disclaimer from "pages/Components/ProductListPage/components/Disclaimer";
import { improveDecimals } from "core/includes/formatters";

type DefaultState = {
    minPriceRange?: number,
    maxPriceRange?: number,
    productGroup: Option,
    productSuitability: Option,
    priceRange: Option,
    sort: Option,
    selectedHearFrom: FormDropdownOption,
    sortField?: string,
    sortDirection?: string,
    showingOrderForm: boolean,
    productListWithQty?: any,
    isSubmitting: boolean,
    showOrderGuidance: boolean,
}

const INITIAL_STATE = {
    productGroup: PRODUCT_GROUP_INIT_OPTION,
    productSuitability: PRODUCT_SUITABILITY_INIT_OPTION,
    priceRange: PRODUCT_PRICE_RANGE_OPTIONS[0],
    sort: PRODUCT_SORT_OPTIONS[0],
    selectedHearFrom: DROPDOWN_INIT_OPTION,
    sortField: PRODUCT_SORT_OPTIONS[0].value,
    sortDirection: "DESC",
    showingOrderForm: false,
    productListWithQty: {},
    isSubmitting: false,
    showOrderGuidance: false,
}

type Props = {
    data: ProductListPage,
};

type PriceRangeType = {
    value: string,
    label: string,
}

const ProductListPage: React.FC<Props> = ({ data: pageData }) => {
    const theme = useTheme();
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);
    const [createResourceOrder] = useMutation(CREATE_RESOURCE_ORDER);
    const [toastSuccess, toastError] = useToast();
    const { bannerHeight } = useListBreakpoint();
    const { breakpoint } = useBreakpoint();

    const disclaimer = "Patent Protection Notice: The resources acquired from Writer's Toolbox are protected by patents, and any unauthorised making, usage, distribution, importation, or sale by third parties requires the explicit consent of the patent owner.";

    const [image, disclaimerPadding] = useMemo(() => {
        switch (true) {
            case isUp(breakpoint, '3xl'):
                return [pageData.landscape || pageData.portrait, '35px 300px'];
            case isOnly(breakpoint, 'xxl'):
                return [pageData.landscape || pageData.portrait, '35px 270px'];
            case isOnly(breakpoint, 'xl'):
                return [pageData.landscape || pageData.portrait, '35px 240px'];
            case isOnly(breakpoint, 'lg'):
                return [pageData.landscape || pageData.portrait, '35px 159px'];
            case isOnly(breakpoint, 'md'):
                return [pageData.portrait || pageData.landscape, '35px 45px'];
            case isOnly(breakpoint, 'sm'):
                return [pageData.portrait || pageData.landscape, '35px 36px'];
            default:
                return [pageData.portrait || pageData.landscape, '24px'];
        }
    }, [breakpoint, pageData]);

    const useAFocusPoint = (isEqual(image, pageData.landscape) && pageData.useFocusPoint) ||
        (isEqual(image, pageData.portrait) && pageData.useFocusPointPortrait);

    const {
        productList,
        loading,
        productGroupOptions,
        productSuitabilityOptions,
        countryCode,
    } = useProductListPageData(
        pageData,
        state.minPriceRange,
        state.maxPriceRange,
        state.productGroup,
        state.productSuitability,
        state.sortField,
        state.sortDirection,
    );

    useEffect(() => {
        setState({ productListWithQty: {} });
    }, [countryCode, setState]);

    // Set init productListWithQty state with 0 of each product quantity value
    const initProductListWithQty = useCallback((isReset?: boolean) => {
        if (!isEmpty(productList)) {
            const productListWithQty = productList.reduce((obj: any, product: any) => {
                obj[`${product.id}`] = { ...product, quantity: 0 };
                return obj;
            }, {});

            setState((prev: DefaultState) => {
                if (isEmpty(prev.productListWithQty) || isReset) {
                    return { productListWithQty }
                }
            });
        }
    }, [productList, setState]);

    useEffect(() => {
        initProductListWithQty();
    }, [initProductListWithQty]);

    const setPriceRange = (priceRange: Option) => {
        const { value } = priceRange;
        const minPriceRange = value.split("/")?.[0];
        const maxPriceRange = value.split("/")?.[1];

        setState({ minPriceRange, maxPriceRange, priceRange });
    };

    const setSortOption = (sort: Option) => {
        const { value } = sort;
        const sortField = value.split("/")?.[0];
        const sortDirection = value.split("/")?.[1] || "DESC";

        setState({ sort, sortField, sortDirection });
    };

    const validator: any = (fields: FieldList, isSubmit: boolean = false) => {
        const errors: any = {};

        if (!fields.firstName) {
            errors.firstName = "Please provide first name";
        }

        if (!fields.lastName) {
            errors.lastName = "Please provide last name";
        }

        if (!fields.schoolName) {
            errors.schoolName = "Please provide school name";
        }

        if (!fields.streetNo) {
            errors.streetNo = "Please provide street number";
        }

        if (!fields.streetName) {
            errors.streetName = "Please provide street name";
        }

        if (!fields.suburb) {
            errors.suburb = "Please provide suburb";
        }

        if (!fields.city) {
            errors.city = "Please provide city";
        }

        if (!fields.postCode) {
            errors.postCode = "Please provide post code";
        }

        if (!fields.contactEmail) {
            errors.contactEmail = "Please provide your contact email";
        }

        if (fields.contactEmail && !EmailValidator.validate(fields.contactEmail) && isSubmit) {
            errors.contactEmail = "Please provide a valid email address";
        }

        if (!fields.emailToInvoice) {
            errors.emailToInvoice = "Please provide your email to invoice";
        }

        if (fields.emailToInvoice && !EmailValidator.validate(fields.emailToInvoice) && isSubmit) {
            errors.emailToInvoice = "Please provide a valid email address";
        }

        if (!fields.hearFrom?.id) {
            errors.hearFrom = "Please choose an option";
        }

        return errors;
    };

    const [fields, errors, onFieldChange, OnReset, validate] = useForm({
        fields: PRODUCT_ORDER_INIT_FIELDS,
        validator,
    });

    const onSelectHearFromDropdown = (name: string, selectedHearFrom: FormDropdownOption) => {
        onFieldChange(name, selectedHearFrom);
        setState({ selectedHearFrom });
    }

    const orderTotal = useMemo(() => {
        const orderTotal = Object.values(state.productListWithQty).reduce((sum: number, cur: any) => {
            sum = sum + cur?.quantity * ((cur?.shouldDiscount && cur?.price?.discountedPrice) || cur?.price?.amount);
            return sum;
        }, 0);

        if(!!orderTotal && state.showOrderGuidance) {
            setState({ showOrderGuidance: false });
        }

        return orderTotal;
    }, [state.productListWithQty, setState, state.showOrderGuidance]);

    const onShowOderForm = () => {
        if(!!orderTotal){
            setState({ showingOrderForm: true });
        } else {
            setState({ showOrderGuidance: true });
        }
    }
    const onCloseOderForm = () => setState({ showingOrderForm: false });

    const orderList = useMemo(() => {
        return Object.values(state.productListWithQty)
            .filter((item: any) => item.quantity)
            .map((item: any) => {
                const obj = {
                    id: item.id,
                    item: item.title,
                    quantity: formatNumber(item.quantity),
                    price: formatNumber((item.shouldDiscount && item.price.discountedPrice) || item.price.amount),
                    total: formatNumber(item.quantity * ((item.shouldDiscount && item.price.discountedPrice) || item.price.amount)),
                };
                return JSON.stringify(obj);
            });
    }, [state.productListWithQty]);

    const resetFormData = useCallback(() => {
        setState({
            showingOrderForm: false,
            selectedHearFrom: DROPDOWN_INIT_OPTION,
        });
        OnReset();

        const reset = true;
        initProductListWithQty(reset);
    }, [setState, OnReset, initProductListWithQty]);

    const getMaxNumber = (number: number) => {
        if (number > 9999) return 9999;
        return number;
    }

    const onSubmitOrder = useCallback(() => {
        if (validate() && !state.isSubmitting) {
            setState({ isSubmitting: true });
            createResourceOrder({
                variables: {
                    firstName: fields.firstName,
                    lastName: fields.lastName,
                    schoolName: fields.schoolName,
                    streetNo: fields.streetNo,
                    streetName: fields.streetName,
                    suburb: fields.suburb,
                    city: fields.city,
                    postCode: fields.postCode,
                    deliveryCountryId: countryCode.id,
                    contactEmail: fields.contactEmail,
                    emailToInvoice: fields.emailToInvoice,
                    orderNo: fields.orderNo,
                    hearFrom: fields.hearFrom.value,
                    notes: fields.notes,
                    promoCode: fields.promoCode,
                    orderList: orderList,
                },
            }).then((response) => {
                if (response.data.createResourceOrder.status) {
                    const responseStatus = parseInt(response.data.createResourceOrder.status);

                    if (responseStatus) {
                        resetFormData();
                        toastSuccess(ORDER_RESPONSE_MESSAGE.success);
                    } else {
                        toastError(ORDER_RESPONSE_MESSAGE.fail);
                    }
                }
                setState({ isSubmitting: false });
            }).catch((error) => {
                setState({ isSubmitting: false });
                toastError(ORDER_RESPONSE_MESSAGE.fail);
            });
        }
    }, [
        setState,
        createResourceOrder,
        state.isSubmitting,
        validate,
        fields,
        orderList,
        resetFormData,
        toastSuccess,
        toastError,
        countryCode,
    ]);

    const wrapperStyle = css`
        height: 100%;
    `;

    const altText = pageData.altText || '';

    const currency = useMemo(() => countryCode.currency || "$", [countryCode]);
    const taxLabel = useMemo(() => (
        (pageData.isTaxIncluded ? 'incl. ' : 'excl .') + (countryCode.taxLabel || 'GST')
    ), [countryCode, pageData.isTaxIncluded]);

    const priceRangeOptions: PriceRangeType[] = useMemo(() => {
        if (currency === "$") return PRODUCT_PRICE_RANGE_OPTIONS;
        const newOptions: PriceRangeType[] = [];
        PRODUCT_PRICE_RANGE_OPTIONS.forEach((option: { value: string, label: string}) => {
            newOptions.push({
                ...option,
                label: option.label.replaceAll("$", currency)
            });
        });

        return newOptions;
    }, [currency]);

    const bodyOverflowHidden = css`
        ${state.showingOrderForm && css`
            body {
                position: fixed;
                left: 0;
                overflow: hidden;
                pointer-events: none;
            }
        `}
    `;

    const descriptionStyle = css`
        margin-top: 50px;
    `;

    return (
        <SectionContainer data={{ backgroundColour: "white" }} overflowHidden>
            <Global styles={bodyOverflowHidden} />
            <Wrapper useMaxWidth={false} flexDirection={"column"} justifyContent={"start"} css={wrapperStyle}>
                {pageData.landscape && (
                    <BannerForAdvertisement
                        type={"success-story"}
                        mainBannerImage={image}
                        useMainBannerFocusPoint={useAFocusPoint}
                        bannerHeight={bannerHeight}
                        showButton={false}
                        useDivForFocusPoint={false}
                        customAltText={altText}
                    />
                )}

                <PageSection type={'success-story'}>
                    {(loading || state.isSubmitting) && (
                        <Loading onTop fitToScreen overlay overlayColour={"white"} delay={500} />
                    )}

                    <PageHeaderAndDropdownsContainer>
                        <PageHeader>
                            Resources
                        </PageHeader>

                        <DropdownContainer>
                            <DropdownButton
                                options={productGroupOptions}
                                onChange={(item) => setState({ productGroup: item })}
                                selectedValue={[state.productGroup]}
                                backgroundColour={DROPDOWN_BACKGROUND_COLOUR}
                                zIndex={theme.zIndex.zIndexMediumHigh}
                            />

                            <DropdownButton
                                options={priceRangeOptions}
                                onChange={setPriceRange}
                                selectedValue={[state.priceRange]}
                                backgroundColour={DROPDOWN_BACKGROUND_COLOUR}
                                zIndex={theme.zIndex.zIndexMediumHigh}
                            />

                            <DropdownButton
                                options={productSuitabilityOptions}
                                onChange={(item) => setState({ productSuitability: item })}
                                selectedValue={[state.productSuitability]}
                                backgroundColour={DROPDOWN_BACKGROUND_COLOUR}
                            />

                            <DropdownButton
                                options={PRODUCT_SORT_OPTIONS}
                                onChange={(item) => setSortOption(item)}
                                selectedValue={[state.sort]}
                                backgroundColour={DROPDOWN_BACKGROUND_COLOUR}
                                optionBackgroundColour={SORT_DROPDOWN_OPTION_BACKGROUND_COLOUR}
                                optionBackgroundHoveredColour={SORT_DROPDOWN_OPTION_BACKGROUND_HOVERED_COLOUR}
                            />
                        </DropdownContainer>
                    </PageHeaderAndDropdownsContainer>

                    <ProductsSection isNoData={isEmpty(productList)}>
                        {(!loading && isEmpty(productList)) && (
                            <NoData />
                        )}

                        {productList?.map((product: ProductItem, index: number) => {
                            const qty = state.productListWithQty[`${product.id}`]?.quantity || 0;
                            const onPlusClick = () => {
                                setState({
                                    productListWithQty: {
                                        ...state.productListWithQty,
                                        [`${product.id}`]: { ...product, quantity: getMaxNumber(qty + 1) }
                                    }
                                });
                            }
                            const onMinusClick = () => {
                                if (qty < 1) return;
                                setState({
                                    productListWithQty: {
                                        ...state.productListWithQty,
                                        [`${product.id}`]: { ...product, quantity: getMaxNumber(qty - 1) }
                                    }
                                });
                            }
                            const onQtyChange = (quantity: React.ChangeEvent<HTMLInputElement>) => {
                                const newValue = parseInt(quantity.target.value.replace(/[^0-9]/g, ''));
                                quantity.target.value = newValue ? newValue.toString() : "0";

                                setState({
                                    productListWithQty: {
                                        ...state.productListWithQty,
                                        [`${product.id}`]: { ...product, quantity: getMaxNumber(newValue) }
                                    }
                                });
                            }

                            return (
                                <ProductContainer key={`${product.id}-${index}`} index={index}>
                                    <DiscountedPriceAndProductImageContainer>
                                        <DiscountedPriceImage shouldDiscount={product.shouldDiscount} price={product.price} />
                                        <ProductImageContainer>
                                            {product.imagesAndVideos[0] && (
                                                <ProductImage image={product.imagesAndVideos[0]} />
                                            )}
                                        </ProductImageContainer>
                                    </DiscountedPriceAndProductImageContainer>

                                    <ProductText text={product.title} />

                                    <ContentSection
                                        height={'unset'}
                                        alignItems={'center'}
                                        flexDirection={'column'}
                                    >
                                        <Price
                                            price={product.price}
                                            shouldDiscount={product.shouldDiscount}
                                            currency={currency}
                                            taxLabel={taxLabel}
                                        />
                                        {!product.outOfStock && (
                                            <RoundQuantityButton
                                                minusClickHandler={onMinusClick}
                                                plusClickHandler={onPlusClick}
                                                onChange={onQtyChange}
                                                quantity={qty}
                                            />
                                        )}
                                    </ContentSection>

                                    <Description
                                        isOutOfStock={product.outOfStock}
                                        expectedDate={product.expectedDate}
                                        description={product.description}
                                    />
                                </ProductContainer>
                            )
                        })}

                        <BorderForSpaces productList={productList}/>
                    </ProductsSection>

                    <GuidanceAndOrderButtonContainer>
                        {state.showOrderGuidance && (
                            <GuidanceText text={"Quantity required to order"} />
                        )}

                        {!isEmpty(productList) && (
                            <OrderButton onClick={onShowOderForm}>Order form</OrderButton>
                        )}
                    </GuidanceAndOrderButtonContainer>

                    <ContentCombine
                        Tag={'p'}
                        fontSizeType={'9'}
                        anchorColour={theme.colours.curiousBlue}
                        css={descriptionStyle}
                    >
                        {pageData.description}
                    </ContentCombine>

                    <Disclaimer padding={disclaimerPadding}>
                        {disclaimer}
                    </Disclaimer>
                </PageSection>

                <FormModal
                    isVisible={state.showingOrderForm}
                    onClose={onCloseOderForm}
                    top={theme.sizes.menu.heightMain + 20}
                    isFixed
                >
                    <FormContainer>
                        <ModalHeader>Order form</ModalHeader>
                        <ModalText>Please fill in the required fields (*)</ModalText>

                        <Form onSubmit={onSubmitOrder}>
                            <FormLabelledField
                                name="firstName"
                                error={errors.firstName}
                                label="First name"
                                errorAsterisk
                            >
                                <FormInput
                                    hasError={!!errors.firstName}
                                    label="Type full name"
                                    name="firstName"
                                    onChange={onFieldChange}
                                    value={fields.firstName}
                                />
                            </FormLabelledField>

                            <FormLabelledField
                                name="lastName"
                                error={errors.lastName}
                                label="Last name"
                                errorAsterisk
                            >
                                <FormInput
                                    hasError={!!errors.lastName}
                                    label="Type last name"
                                    name="lastName"
                                    onChange={onFieldChange}
                                    value={fields.lastName}
                                />
                            </FormLabelledField>

                            <FormLabelledField
                                name="schoolName"
                                error={errors.schoolName}
                                label="School name"
                                errorAsterisk
                            >
                                <FormInput
                                    hasError={!!errors.schoolName}
                                    label="Type school name"
                                    name="schoolName"
                                    onChange={onFieldChange}
                                    value={fields.schoolName}
                                />
                            </FormLabelledField>

                            <FormText>School delivery address</FormText>

                            <FormLabelledField
                                name="streetNo"
                                error={errors.streetNo}
                                label="Street no."
                                errorAsterisk
                            >
                                <FormInput
                                    hasError={!!errors.streetNo}
                                    label="Type street no."
                                    name="streetNo"
                                    onChange={onFieldChange}
                                    value={fields.streetNo}
                                />
                            </FormLabelledField>

                            <FormLabelledField
                                name="streetName"
                                error={errors.streetName}
                                label="Street name"
                                errorAsterisk
                            >
                                <FormInput
                                    hasError={!!errors.streetName}
                                    label="Type street name"
                                    name="streetName"
                                    onChange={onFieldChange}
                                    value={fields.streetName}
                                />
                            </FormLabelledField>

                            <FormLabelledField
                                name="suburb"
                                error={errors.suburb}
                                label="Suburb"
                                errorAsterisk
                            >
                                <FormInput
                                    hasError={!!errors.suburb}
                                    label="Type suburb"
                                    name="suburb"
                                    onChange={onFieldChange}
                                    value={fields.suburb}
                                />
                            </FormLabelledField>

                            <FormLabelledField
                                name="city"
                                error={errors.city}
                                label="City"
                                errorAsterisk
                            >
                                <FormInput
                                    hasError={!!errors.city}
                                    label="Type city"
                                    name="city"
                                    onChange={onFieldChange}
                                    value={fields.city}
                                />
                            </FormLabelledField>

                            <FormLabelledField
                                name="postCode"
                                error={errors.postCode}
                                label="Post code"
                                errorAsterisk
                            >
                                <FormInput
                                    hasError={!!errors.postCode}
                                    label="Type post code"
                                    name="postCode"
                                    onChange={onFieldChange}
                                    value={fields.postCode}
                                />
                            </FormLabelledField>

                            <FormLabelledField
                                name="country"
                                label="Country"
                            >
                                <FormInput
                                    label="Country"
                                    name="country"
                                    value={countryCode?.name}
                                />
                            </FormLabelledField>

                            <FormText />

                            <FormLabelledField
                                name="contactEmail"
                                error={errors.contactEmail}
                                label="Contact email"
                                errorAsterisk
                            >
                                <FormInput
                                    hasError={!!errors.contactEmail}
                                    label="Type contact email"
                                    name="contactEmail"
                                    onChange={onFieldChange}
                                    value={fields.contactEmail}
                                />
                            </FormLabelledField>

                            <FormLabelledField
                                name="emailToInvoice"
                                error={errors.emailToInvoice}
                                label="Email to invoice"
                                errorAsterisk
                            >
                                <FormInput
                                    hasError={!!errors.emailToInvoice}
                                    label="Type email to invoice"
                                    name="emailToInvoice"
                                    onChange={onFieldChange}
                                    value={fields.emailToInvoice}
                                />
                            </FormLabelledField>

                            <FormLabelledField
                                name="hearFrom"
                                label="How did you hear about our products?"
                                error={errors.hearFrom}
                                labelWidth={"50%"}
                                labelLeft={"50%"}
                                errorAsterisk
                            >
                                <FormDropDown
                                    selected={state.selectedHearFrom}
                                    setSelected={(selectedOption: FormDropdownOption) => onSelectHearFromDropdown("hearFrom", selectedOption)}
                                    options={HEAR_FROM_OPTIONS}
                                    hasError={!!errors.hearFrom}
                                />
                            </FormLabelledField>

                            <FormLabelledField
                                name="orderNo"
                                label="Order no."
                            >
                                <FormInput
                                    label="Type order no."
                                    name="orderNo"
                                    onChange={onFieldChange}
                                    value={fields.orderNo}
                                />
                            </FormLabelledField>

                            <FormLabelledField
                                name="promoCode"
                                label="Promo code"
                            >
                                <FormInput
                                    label="Promo code"
                                    name="promoCode"
                                    onChange={onFieldChange}
                                    value={fields.promoCode}
                                />
                            </FormLabelledField>

                            <FormLabelledField
                                name="notes"
                                label="Notes"
                            >
                                <FormInput
                                    label="Notes"
                                    name="notes"
                                    onChange={onFieldChange}
                                    value={fields.notes}
                                />
                            </FormLabelledField>

                            <FormText>Order</FormText>

                            <OrderSummaryTable orderList={orderList} currency={currency} taxLabel={taxLabel} />

                            <FormText noMarginTop>
                                Total: {currency}{formatNumber(orderTotal)} {taxLabel} & postage
                            </FormText>

                            <OrderInfoText>
                                For school orders, payment is due 10 days following invoice date.
                            </OrderInfoText>

                            <Disclaimer fontSize={12} lineHeight={14}>
                                {disclaimer}
                            </Disclaimer>

                            <FormActions justifyContent={"center"}>
                                <FormButton type={"submit"}>
                                    Submit order
                                </FormButton>
                                <FormButton type={"button"} onButtonClick={onCloseOderForm}>
                                    Cancel
                                </FormButton>
                            </FormActions>
                        </Form>
                    </FormContainer>
                </FormModal>
            </Wrapper>
        </SectionContainer>
    );
};

export default ProductListPage;

const formatNumber = (number: number) => {
    // Make sure there are 2 decimal places if at least 1 is present.
    // Add a comma for values over 1000.
    return improveDecimals(number).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
